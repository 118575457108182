// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as HelpBlock from "../help-block/HelpBlock.res.js";
import * as SignUpForm from "../../common/signup/SignUpForm.res.js";
import * as SignUpScss from "./SignUp.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = SignUpScss;

function SignUp$SignUp(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(H1.make, {
                                          children: "Sign Up"
                                        }),
                                    props.invalidInvitation ? JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("p", {
                                                        children: "This invitation is no longer active. Please sign up for Datacenters.com."
                                                      }),
                                                  className: css.info
                                                }),
                                            className: Cx.cx([
                                                  css.messageContainer,
                                                  css.info
                                                ])
                                          }) : null,
                                    JsxRuntime.jsx(SignUpForm.make, {
                                          onSignedUp: (function (param) {
                                              
                                            }),
                                          onSignIn: (function () {
                                              Url.visit("/users/sign_in");
                                            }),
                                          form: "SignUp"
                                        })
                                  ],
                                  className: Cx.cx([
                                        css.column,
                                        css.signUp
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(HelpBlock.make, {}),
                                  className: Cx.cx([
                                        css.column,
                                        css.helpContainer
                                      ])
                                })
                          ],
                          className: css.row
                        }),
                    className: css.container
                  }),
              className: css.signUpContainer
            });
}

var SignUp = {
  make: SignUp$SignUp
};

function SignUp$default(props) {
  return JsxRuntime.jsx(SignUp$SignUp, {
              invalidInvitation: props.props.invalidInvitation
            });
}

var $$default = SignUp$default;

export {
  css ,
  SignUp ,
  $$default as default,
}
/* css Not a pure module */
